import { Box, Fade, Grid, Typography } from "@mui/material";
import React from "react";
import ParallexComponent from "../Layout/ParallexComponent";
import VizSensor from 'react-visibility-sensor';
import { useState } from "react";
import pic1 from "../../Images/DiashowHaus/HausAussen.jpeg"
import pic2 from "../../Images/DiashowHaus/Kaminzimmer.jpeg"
import pic3 from "../../Images/DiashowHaus/Treppe.jpeg"
import pic4 from "../../Images/DiashowHaus/Bismarkzimmer.jpeg"
import pic5 from "../../Images/DiashowHaus/AHZimmer.jpeg"
import SimpleImageSlider from "react-simple-image-slider";

interface IProps {
  refHaus?: React.MutableRefObject<HTMLInputElement | null>;
}

const images = [
    { url: pic1 },
    { url: pic2 },
    { url: pic3 },
    { url: pic4 },
    { url: pic5 },
  ];

const Haus:React.FC<IProps> = (props) => {

    let [active, setActive] = useState(false);

    return(
        <ParallexComponent>
            <>
            <Typography id='Wo' ref={props.refHaus} variant="h4" align="center" sx={{ pb: "5%"}}><b>Corpshaus der Guestphalia Berlin</b></Typography>

            {/*View on Desktop (Bigger than 900px*/}
            <Box sx={{ display: { xs: 'none', md: 'block' }}}>
            <VizSensor
            partialVisibility={true}
            minTopValue={100}
            onChange={(isVisible: any) => {
            setActive(isVisible);}}>

            <Fade in={active} timeout={500}>
            <Grid container spacing={2}>

                <Grid item sm={6}>
                
                <Box minHeight="600px">
                <SimpleImageSlider
                    width={"40%"}
                    height={"55%"}
                    images={images}
                    showBullets={true}
                    showNavs={true}
                    style={{alignContent:"center"}}
                />
                </Box>

                </Grid>
                <Grid item sm={6}>
                    <Box>
                        <Typography align="center">
                        Das Verbindungshaus in der Schwendenerstraße 13, gelegen in Dahlem im Berliner Bezirk Steglitz-Zehlendorf, ist ein wunderschönes altes Gebäude, das die bewegte Geschichte und Tradition einer der ältesten Studentenverbindungen Berlins verkörpert. Das Haus wurde 1929 erbaut und dient seit 1957 als Heimstätte für das Corps Guestphalia.
                        <br></br>
                        Das im Villenstil um 1900 errichtete dreigeschossige Gebäude beeindruckt durch seine zurückgenommene aber elegante Architektur. Die verzierte und mit Säulen geschmückte Fassade verleiht dem Haus eine besondere Ausstrahlung
                        <br></br>
                        Auch im Inneren finden sich zahlreiche historische Details, die von der Geschichte des Corps zeugen. Von seiner besonderen Gemütlichkeit schwärmen die Corpsbrüder bis heute: Vor allem das Kamin- und Altherren-Zimmern werden besonders gern gezeigt und genutzt. 
                        <br></br>
                        Von den insgesamt 15 Räumen werden neun als Aktivenzimmer genutzt. Unser großer Garten lädt nicht nur im Sommer zum Feiern ein.
                        Somit ist unser Corpshaus in der Schwendenerstraße nicht nur ein wunderschönes Gebäude, sondern ein wichtiger Ort für die Mitglieder der Guestphalia
                        <br></br>
                        Regelmäßig finden hier Veranstaltungen, wie Kneipen, Vorträge und Feiern statt, die das Zusammengehörigkeitsgefühl der Corpsbrüder stärken.
                        </Typography>
                </Box>

                </Grid>
            </Grid>
            </Fade>
            </VizSensor>
            </Box>

            {/*View on Mobile (Smaller than 900px*/}
            <Box sx={{ display: { xs: 'block', md: 'none' }} }>
                <Grid container spacing={2}>

                <Grid item sm={6}>
                <Box minHeight="250px">
                <SimpleImageSlider
                    width={"80%"}
                    height={"30%"}
                    images={images}
                    showBullets={true}
                    showNavs={true}
                    style={{alignContent:"center"}}
                />
                </Box>
                
                </Grid>

                <Grid item sm={6}>
                <Box>
                    <Typography align="center">
                    Das Verbindungshaus in der Schwendenerstraße 13, gelegen in Dahlem im Berliner Bezirk Steglitz-Zehlendorf, ist ein wunderschönes altes Gebäude, das die bewegte Geschichte und Tradition einer der ältesten Studentenverbindungen Berlins verkörpert. Das Haus wurde 1929 erbaut und dient seit 1957 als Heimstätte für das Corps Guestphalia.
                        <br></br>
                        Das im Villenstil um 1900 errichtete dreigeschossige Gebäude beeindruckt durch seine zurückgenommene aber elegante Architektur. Die verzierte und mit Säulen geschmückte Fassade verleiht dem Haus eine besondere Ausstrahlung
                        <br></br>
                        Auch im Inneren finden sich zahlreiche historische Details, die von der Geschichte des Corps zeugen. Von seiner besonderen Gemütlichkeit schwärmen die Corpsbrüder bis heute: Vor allem das Kamin- und Altherren-Zimmern werden besonders gern gezeigt und genutzt. 
                        <br></br>
                        Von den insgesamt 15 Räumen werden neun als Aktivenzimmer genutzt. Unser großer Garten lädt nicht nur im Sommer zum Feiern ein.
                        Somit ist unser Corpshaus in der Schwendenerstraße nicht nur ein wunderschönes Gebäude, sondern ein wichtiger Ort für die Mitglieder der Guestphalia
                        <br></br>
                        Regelmäßig finden hier Veranstaltungen, wie Kneipen, Vorträge und Feiern statt, die das Zusammengehörigkeitsgefühl der Corpsbrüder stärken.
                    </Typography>
                </Box>

                </Grid>
            </Grid>   
            </Box>

            </>
        </ParallexComponent>
    )
}
export default Haus;