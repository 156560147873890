
import Corps from "../Content/Corps";
import ContactForm from "../Content/ContactForm";
import Haus from "../Content/Haus";
import Studentenverbindungen from "../Content/Verbindungen";
import Veranstaltungen from "../Content/Veranstaltungen";
import Vorteile from "../Content/Vorteile";
import LoginPage from "../Login/LoginPage";
import React, {  useState } from 'react';
import ChatOnWatsappIcon from "../../Images/Whatsapp.png"
import { Typography } from "@mui/material";


interface IProps {
    refContact?: React.MutableRefObject<HTMLInputElement | null>;
    refCorps?: React.MutableRefObject<HTMLInputElement | null>;
    refHaus?: React.MutableRefObject<HTMLInputElement | null>;
    refVerbindungen?: React.MutableRefObject<HTMLInputElement | null>;
    refVeranstaltungen?: React.MutableRefObject<HTMLInputElement | null>;
    refVorteile?: React.MutableRefObject<HTMLInputElement | null>;
  }


const MainPage:React.FC<IProps> = (props) => {

    var [isValidated,setIsValidated] = useState<boolean | null>(null);

    isValidated = true;
    
    if(!isValidated)
    {
        return <LoginPage setIsValidated={setIsValidated}/>
    }
    if(isValidated)
    {
        return(
        <>
        <Corps refCorps={props.refCorps}/>
        <Vorteile refVorteile={props.refVorteile}></Vorteile>
        <Studentenverbindungen refVerbindungen={props.refVerbindungen}/>
        <Veranstaltungen refVeranstaltungen={props.refVeranstaltungen}/>
        <Haus refHaus={props.refHaus}/>
        <ContactForm refContact={props.refContact}/>
        </>
        )
    }
    return(
        <>
        </>
    )
} 
export default MainPage;