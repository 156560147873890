import { AppBar, Box, Grid, Link,} from "@mui/material";
import React from "react";



const Footer:React.FC = () => {
    return(
        <>
             <AppBar position="relative" sx={{ bgcolor: '#000000;' }}>
                <Box sx={{pr: "10%", pl: "10%", pt: "2%", pb: "2%"}}>
                    <Grid container>

                        <Grid item sm={6}>
                            <table>
                                <tbody>
                                    <tr><td><Link href="/" color="inherit">Startseite</Link></td></tr>
                                    <tr><td><Link href="/imprint" color="inherit">Impressum</Link></td></tr>
                                    <tr><td><Link href="https://guestphalia-berlin.corpshaus2.de" color="inherit">Intern</Link></td></tr>
                                    <tr><td>Made in Berlin by <Link href="https://www.backantz.de/" color="inherit">BackAntz</Link></td></tr>
                                </tbody>
                            </table>
                        </Grid>

                    </Grid>
                </Box>
             </AppBar>
        </>
    )
}
export default Footer;