import { Box,Typography, Fade } from "@mui/material";
import ParallexComponent from "../Layout/ParallexComponent";
import VizSensor from 'react-visibility-sensor';
import React, { useState } from "react";

interface IProps {
    refVerbindungen?: React.MutableRefObject<HTMLInputElement | null>;
}

const Was:React.FC<IProps> = (props) => {

    let [active, setActive] = useState(false);


    return(
        
        <ParallexComponent>
            <>
            <Typography ref={props.refVerbindungen} variant="h4" align="center" sx={{ pb: "5%"}}><b>Verbindungen</b></Typography>

            <VizSensor
            partialVisibility={true}
            minTopValue={20}
            onChange={(isVisible: any) => {
            setActive(isVisible);}}>

            <Fade in={active} timeout={500}>
                {/*View on Desktop (Bigger than 900px*/}
                <Box sx={{ display: { xs: 'none', md: 'block' }}}>
                <Typography align="center">
                Eine Studentenverbindung in Deutschland ist eine Gruppe von Studenten, die sich auf freiwilliger Basis zu einer Gemeinschaft zusammenschließen. Diese Gemeinschaften haben in der Regel einen starken Fokus auf Traditionen, Rituale und Werte, die oft seit Jahrzehnten oder sogar Jahrhunderten Bestand haben.
                <br></br>
                Studentenverbindungen sind in Deutschland in verschiedene Typen unterteilt, wie zum Beispiel Studentenverbindungen mit einem politischen Schwerpunkt, die sich auf die Pflege bestimmter akademischer Disziplinen konzentrieren oder sich auf spezielle Interessengebiete wie Sport oder Musik konzentrieren. Es gibt auch Verbindungen, die ausschließlich für Frauen oder Männer bestimmt sind, obwohl in jüngerer Zeit auch gemischte Verbindungen zunehmend beliebt geworden sind.
                <br></br>
                Die Mitgliedschaft in einer Studentenverbindung in Deutschland ist in der Regel sehr engagiert und erfordert von den Mitgliedern einen hohen Grad an Hingabe und Engagement. Viele Studentenverbindungen organisieren eine Vielzahl von Veranstaltungen und Aktivitäten wie Vorträge, gesellige Treffen, Sportveranstaltungen und Ausflüge, um das Gemeinschaftsgefühl und die Freundschaft unter den Mitgliedern zu fördern.
                </Typography>
                </Box>
            </Fade>
            </VizSensor>

            {/*View on Mobile (Smaller than 900px*/}
            <Box sx={{ display: { xs: 'block', md: 'none' }} }>
            <Typography align="center">
            Eine Studentenverbindung in Deutschland ist eine Gruppe von Studenten, die sich auf freiwilliger Basis zu einer Gemeinschaft zusammenschließen. Diese Gemeinschaften haben in der Regel einen starken Fokus auf Traditionen, Rituale und Werte, die oft seit Jahrzehnten oder sogar Jahrhunderten Bestand haben.                <br></br>
            Studentenverbindungen sind in Deutschland in verschiedene Typen unterteilt, wie zum Beispiel Studentenverbindungen mit einem politischen Schwerpunkt, die sich auf die Pflege bestimmter akademischer Disziplinen konzentrieren oder sich auf spezielle Interessengebiete wie Sport oder Musik konzentrieren. Es gibt auch Verbindungen, die ausschließlich für Frauen oder Männer bestimmt sind, obwohl in jüngerer Zeit auch gemischte Verbindungen zunehmend beliebt geworden sind.
            <br></br>
            Die Mitgliedschaft in einer Studentenverbindung in Deutschland ist in der Regel sehr engagiert und erfordert von den Mitgliedern einen hohen Grad an Hingabe und Engagement. Viele Studentenverbindungen organisieren eine Vielzahl von Veranstaltungen und Aktivitäten wie Vorträge, gesellige Treffen, Sportveranstaltungen und Ausflüge, um das Gemeinschaftsgefühl und die Freundschaft unter den Mitgliedern zu fördern.
            </Typography>
            </Box>

            </>
        </ParallexComponent>
    )
}
export default Was;