import React, { useState } from "react";
import ParallexComponent from "../Layout/ParallexComponent";
import { Box, Fade, Grid, Typography } from "@mui/material";
import Anschluss from "../../Images/ImagesVorteile/Anschluss.jpeg"
import FUBerlin from "../../Images/ImagesVorteile/FUBerlin.jpg"
import Nachhilfe from "../../Images/ImagesVorteile/Nachhilfe.jpg"
import Berufsleben from "../../Images/ImagesVorteile/Berufsleben.jpg"
import VizSensor from 'react-visibility-sensor';



interface IProps {
    refVorteile?: React.MutableRefObject<HTMLInputElement | null>;
}


const Vorteile: React.FC<IProps> = (props) => {
    let [active, setActive] = useState(false);

    return (
        <ParallexComponent>
            <Box>

                <Typography id='Vorteile' ref={props.refVorteile} variant="h4" align="center" sx={{ pb: "5%" }}><b>Vorteile von Guestphalia</b></Typography>


                {/*View on Desktop (Bigger than 900px*/}
                <Box sx={{ display: { xs: 'none', md: 'block' } }}>

                    <VizSensor
                        partialVisibility={true}
                        minTopValue={100}
                        onChange={(isVisible: any) => {
                            setActive(isVisible);
                        }}>

                        <Fade in={active} timeout={500}>

                            <Grid container spacing={2} >
                                <Grid item sm={6}>
                                    <Typography align="center"><Box><img src={Anschluss} width={"75%"} height={"75%"} alt="Anschluss" ></img></Box></Typography>
                                </Grid>
                                <Grid item sm={6}>
                                    <Typography align="center">
                                        In einer Studentenverbindung Anschluss zu finden, kann eine spannende Erfahrung sein.
                                        Das Wichtigste ist, du selbst zu sein, offen und respektvoll zu sein und aktiv an den
                                        Aktivitäten der Verbindung teilzunehmen. Mit der Zeit wirst du Anschluss finden und eine
                                        positive Erfahrung in deiner Studentenverbindung machen. Das Wohnen in der Nähe der Freien
                                        Universität (FU) Berlin, einer der renommiertesten Universitäten in Deutschland, bietet zudem viele Vorteile für Studenten.
                                    </Typography>
                                </Grid>
                                <Grid item sm={6}>
                                    <Typography align="center">
                                        Das Wohnen in der Nähe der Freien Universität (FU) Berlin, einer der
                                        renommiertesten Universitäten in Deutschland, bietet viele Vorteile für Studierende,
                                        <br></br>
                                        darunter fällt Zeitersparnis, einfachen Zugang zu Ressourcen,
                                        aktives studentisches Leben, Vernetzungsmöglichkeiten, Flexibilität und
                                        Unabhängigkeit sowie das Erleben des Stadtlebens und der Kultur in Berlin.</Typography>
                                </Grid>
                                <Grid item sm={6}>
                                    <Typography align="center"><Box><img src={FUBerlin} width={"75%"} height={"75%"} alt="FUBerlin"></img></Box></Typography>
                                </Grid>
                                <Grid item sm={6}>
                                    <Typography align="center"><Box><img src={Nachhilfe} width={"75%"} height={"75%"} alt="Nachhilfe"></img></Box></Typography>
                                </Grid>
                                <Grid item sm={6}>
                                    <Typography align="center">
                                        Erfahrene Studierende, die ihre Studienzeit bereits absolviert haben,
                                        können wertvolle Hilfe und Unterstützung für neue Studenten bieten.
                                        <br></br>
                                        Sie können praktische Tipps und
                                        Ratschläge geben, ihre eigenen Erfahrungen teilen, als Mentoren dienen,
                                        bei der sozialen Integration unterstützen und den akademischen Erfolg fördern.
                                        Die Unterstützung von erfahrenen Studierenden kann dazu beitragen, dass neue
                                        Studierende erfolgreich in ihr Studium starten und sich schneller in die
                                        studentische Gemeinschaft integrieren.
                                    </Typography>
                                </Grid>
                                <Grid item sm={6}>
                                    <Typography align="center" >
                                        Freunde können einen wertvollen Beitrag dazu leisten,
                                        dass der Einstieg ins Berufsleben einfacher wird.
                                        Durch Netzwerken, gemeinsame Karriereinteressen,
                                        Ratschläge und Erfahrungsaustausch können Freunde dazu beitragen,
                                        dass man besser in das Berufsleben startet und die Herausforderungen besser bewältigt.
                                        Es ist wichtig, das Potenzial von Freundschaften für die berufliche Entwicklung zu erkennen und diese Beziehungen zu pflegen.
                                    </Typography>
                                </Grid>
                                <Grid item sm={6}>
                                    <Typography align="center"><Box ><img src={Berufsleben} width={"75%"} height={"75%"} alt="Berufsleben"></img></Box></Typography>
                                </Grid>
                            </Grid>

                        </Fade>
                    </VizSensor>
                </Box>

                {/*View on Mobile (Smaller than 900px*/}
                <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                    <Grid container spacing={2}>
                        <Grid item sm={6}>
                            <Typography align="center"><Box><img src={Anschluss} width={"75%"} height={"75%"} alt="Anschluss" ></img></Box></Typography>
                        </Grid>
                        <Grid item sm={6}>
                            <Typography align="center">
                                In einer Studentenverbindung Anschluss zu finden, kann eine spannende Erfahrung sein.
                                Das Wichtigste ist, du selbst zu sein, offen und respektvoll zu sein und aktiv an den
                                Aktivitäten der Verbindung teilzunehmen. Mit der Zeit wirst du Anschluss finden und eine
                                positive Erfahrung in deiner Studentenverbindung machen. Das Wohnen in der Nähe der Freien
                                Universität (FU) Berlin, einer der renommiertesten Universitäten in Deutschland, bietet zudem viele Vorteile für Studenten.
                            </Typography>
                        </Grid>

                        <Grid item sm={6}>
                            <Typography align="center"><Box><img src={FUBerlin} width={"75%"} height={"75%"} alt="FUBerlin"></img></Box></Typography>
                        </Grid>
                        <Grid item sm={6}>
                            <Typography align="center">
                                Das Wohnen in der Nähe der Freien Universität (FU) Berlin, einer der
                                renommiertesten Universitäten in Deutschland, bietet viele Vorteile für Studierende,
                                <br></br>
                                darunter fällt Zeitersparnis, einfachen Zugang zu Ressourcen,
                                aktives studentisches Leben, Vernetzungsmöglichkeiten, Flexibilität und
                                Unabhängigkeit sowie das Erleben des Stadtlebens und der Kultur in Berlin.</Typography></Grid>
                        <Grid item sm={6}>
                            <Typography align="center"><Box><img src={Nachhilfe} width={"75%"} height={"75%"} alt="Nachhilfe"></img></Box></Typography>
                        </Grid>
                        <Grid item sm={6}>
                            <Typography align="center">
                                Erfahrene Studierende, die ihre Studienzeit bereits absolviert haben,
                                können wertvolle Hilfe und Unterstützung für neue Studenten bieten.
                                <br></br>
                                Sie können praktische Tipps und
                                Ratschläge geben, ihre eigenen Erfahrungen teilen, als Mentoren dienen,
                                bei der sozialen Integration unterstützen und den akademischen Erfolg fördern.
                                Die Unterstützung von erfahrenen Studierenden kann dazu beitragen, dass neue
                                Studierende erfolgreich in ihr Studium starten und sich schneller in die
                                studentische Gemeinschaft integrieren.
                            </Typography>
                        </Grid>
                        <Grid item sm={6}>
                            <Typography align="center"><Box ><img src={Berufsleben} width={"75%"} height={"75%"} alt="Berufsleben"></img></Box></Typography>
                        </Grid>
                        <Grid item sm={6}>
                            <Typography align="center" >
                                Freunde können einen wertvollen Beitrag dazu leisten,
                                dass der Einstieg ins Berufsleben einfacher wird.
                                Durch Netzwerken, gemeinsame Karriereinteressen,
                                Ratschläge und Erfahrungsaustausch können Freunde dazu beitragen,
                                dass man besser in das Berufsleben startet und die Herausforderungen besser bewältigt.
                                Es ist wichtig, das Potenzial von Freundschaften für die berufliche Entwicklung zu erkennen und diese Beziehungen zu pflegen.
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </ParallexComponent>
    )
}
export default Vorteile;