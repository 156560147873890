import {Box, Alert, Grid, Snackbar, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import ParallexComponent from "../Layout/ParallexComponent";
import { Button } from '@mui/material';
import ChatOnWatsappIcon from "../../Images/Whatsapp.png"

interface IContact  {
    Name: string;
    EMail: string;
    Message: string;
}


interface IProps {
    refContact?: React.MutableRefObject<HTMLInputElement | null>;
}


const ContactForm:React.FC<IProps> = (props) => {
    const [contactObject, setContactObject] = useState({
        Name: "",
        EMail: "",
        Message: ""
    } as IContact)
    const [wasSend, setWasSend] = useState(false);

    async function  handleClickSend() {
        // Settings 
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(contactObject)
        };

        fetch('/mail.php', requestOptions)
        .then(res => {
            if (res.status === 200) { return res.json()}
            else {throw Error(`ERROR`)}
        })
        .then(res => {
            setContactObject({
                Name: "",
                EMail: "",
                Message: ""
            })
            setWasSend(true);
        })
    }

    return(
        <ParallexComponent isLast={true}>
            <>
                <Snackbar 
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    open={wasSend} 
                    autoHideDuration={6000} 
                    onClose={() => setWasSend(false)}
                >
                    <Alert onClose={() => setWasSend(false)} severity="success" sx={{ width: '100%' }}>
                        Erfolgreich versendet!
                    </Alert>
                </Snackbar>

                <Typography 
                    variant="h4" 
                    align="center" 
                    id='Kontakt'
                    ref={props.refContact}
                ><b>Interesse? Dann melde dich doch bei uns!</b></Typography>
                <br />
                <br />


                {/*View on Desktop (Bigger than 900px*/}
                <Box sx={{ display: { xs: 'none', md: 'block' }}}>
                <Grid container spacing={2}>

                    <Grid item sm={6}>
                        <TextField
                            id="Nachname"
                            label="Name"
                            fullWidth
                            required
                            value={contactObject.Name}
                            onChange={(event:React.ChangeEvent<HTMLInputElement>) => setContactObject({...contactObject, Name: event.target.value})}
                        />
                    </Grid>

                    <Grid item sm={6}>
                        <TextField
                            id="E-Mail"
                            label="E-Mail"
                            fullWidth
                            required
                            value={contactObject.EMail}
                            onChange={(event:React.ChangeEvent<HTMLInputElement>) => setContactObject({...contactObject, EMail: event.target.value})}
                        />
                    </Grid>

                    <Grid item sm={12}>
                        <TextField
                            id="Nachricht"
                            label="Nachricht"
                            multiline
                            minRows={6}
                            required
                            fullWidth
                            value={contactObject.Message}
                            onChange={(event:React.ChangeEvent<HTMLInputElement>) => setContactObject({...contactObject, Message: event.target.value})}
                        />
                    </Grid>
                    
                    <Grid item sm={12}>
                    <Button 
                        disabled={
                            contactObject.Name === "" 
                            || contactObject.EMail === "" 
                            || contactObject.Message === "" 
                        }
                        onClick={handleClickSend}
                        variant="contained" color="success" sx={{float: "right"}}
                    >Anfrage absenden</Button>
                    </Grid>
                    <Grid item sm={12}>
                        <Box sx={{float: "right"}}>
                        <a aria-label="Chat on WhatsApp" href="https://wa.me/+1845?text=Bierjunge"> <img alt="Chat on WhatsApp" src={ChatOnWatsappIcon} width={175} height={35}/> </a>
                        </Box>
                    </Grid>
                </Grid>
            </Box>



            {/*View on Mobile (Smaller than 900px*/}
            <Box sx={{ display: { xs: 'block', md: 'none' }} }>
            <Grid container spacing={2}> 
            <Box sx={{mx:'auto', pb:"10px"}}>
                    <Grid item sm={12}>
                        <TextField
                            id="Nachname"
                            label="Name"
                            fullWidth
                            required
                            value={contactObject.Name}
                            onChange={(event:React.ChangeEvent<HTMLInputElement>) => setContactObject({...contactObject, Name: event.target.value})}
                        />
                    </Grid>
            </Box>
            <Box sx={{mx:'auto', pb:"10px"}}> 
                    <Grid item sm={12}>
                        <TextField
                            id="E-Mail"
                            label="E-Mail"
                            fullWidth
                            required
                            value={contactObject.EMail}
                            onChange={(event:React.ChangeEvent<HTMLInputElement>) => setContactObject({...contactObject, EMail: event.target.value})}
                        />
                    </Grid>
            </Box>
            <Box sx={{mx:'auto', pb:"10px"}}> 
                    <Grid item sm={12}>
                        <TextField
                            id="Nachricht"
                            label="Nachricht"
                            fullWidth
                            multiline
                            minRows={5}
                            required
                            value={contactObject.Message}
                            onChange={(event:React.ChangeEvent<HTMLInputElement>) => setContactObject({...contactObject, Message: event.target.value})}
                        />
                    </Grid>
            </Box>        
                    <Grid item sm={6}>
                    <Button 
                        disabled={
                            contactObject.Name === "" 
                            || contactObject.EMail === "" 
                            || contactObject.Message === "" 
                        }
                        onClick={handleClickSend}
                        variant="contained" color="success"
                    >Anfrage absenden</Button>
                    </Grid>
                    <Grid item sm={12}>
                    <a aria-label="Chat on WhatsApp" href="https://wa.me/+000?text=Bierjunge"> <img alt="Chat on WhatsApp" src={ChatOnWatsappIcon} width={175} height={35}/> </a>
                    </Grid>
                </Grid>
            </Box>
            </>
        </ParallexComponent>
    )
}
export default ContactForm;