import { Box } from "@mui/material";
import React from "react";


interface IProps {
    isFirst?: boolean;
    isLast?: boolean;
    children?: React.ReactElement;
}


const ParallexComponent:React.FC<IProps> = (props) => {


  var distanceInPercent;
    {/*View on Desktop (Bigger than 900px*/}
    if(window.innerWidth > 900)
    {
      distanceInPercent = ''+20+'%'
    }
    {/*View on Mobile (Smaller than 900px*/}
    if(window.innerWidth < 900)
    {
      distanceInPercent = ''+50+'%'     
    }

    return(
      <Box>
      <Box 
          sx={{
              mt: (props.isFirst === true) ? "10%" : distanceInPercent, 
              mb: (props.isLast === true) ? "0%" : distanceInPercent, 
              /* BackgroundColor Grau, Color ist die Schriftfarbe */
              backgroundColor: "#F5F5F5", 
              color: "#000000",
              boxShadow: 10
          }}>
          <Box sx={{pr: "10%", pl: "10%", pt: "5%", pb: "5%"}}>
              {props.children}
          </Box>
      </Box>
  </Box>
    )
}
export default ParallexComponent;
