import React, { useEffect, useRef, useState } from 'react';
import './App.css';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, MenuItem, TextField, Typography } from '@mui/material';
import Navbar from './components/Layout/Navbar';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import MainPage from './components/Sites/MainPage';
import Footer from './components/Layout/Footer';
import Imprint from './components/Sites/Imprint';
import ChatOnWatsappIcon from "../src/WhatsAppLogo.png"


function App() {
  const [isOpen, setIsOpen] = useState(false);
  const refContact = useRef<HTMLInputElement | null>(null)
  const refCorps = useRef<HTMLInputElement | null>(null)
  const refHaus = useRef<HTMLInputElement | null>(null)
  const refVerbindungen = useRef<HTMLInputElement | null>(null)
  const refVeranstaltungen = useRef<HTMLInputElement | null>(null)
  const refVorteile = useRef<HTMLInputElement | null>(null)

  //
  const [currentCookieSettings, setCurrentCookieSettings] = useState(false);


    useEffect(() => {
      let cookieSettings = localStorage.getItem("useCookie");

      if (cookieSettings == undefined) {
        //setIsOpen(true);
      }
    },[])


  const handleClickSaveAll = () => {
    localStorage.setItem("useCookie", "1");
    setIsOpen(false);
  }

  const handleClickSave = () => {
    localStorage.setItem("useCookie", (currentCookieSettings === true) ? "1" : "0");
    setIsOpen(false);
  }


  return (
    <>
      <Dialog
        open={isOpen}
      >
        <DialogTitle>Hallo!</DialogTitle>

        <DialogContent>
          <DialogContentText>
            Du scheinst das erste Mal auf unserer Seite zu sein. 
            Bitte setze die Einstellungen der Seite nach deinen Präferenzen.
          </DialogContentText>

          <br />
          <br />
          <TextField
            label="Cookie"
            value={(currentCookieSettings === true) ? 1 : 0}
            onChange={(event:React.ChangeEvent<HTMLInputElement>) => setCurrentCookieSettings(Number(event.target.value) === 1)}
            fullWidth
            size="small"
            select>
              
            <MenuItem value={0}>Nur technische Cookies</MenuItem>
            <MenuItem value={1}>Alle Cookies</MenuItem>
          </TextField>
        </DialogContent>

        <DialogActions>
          <Button variant="outlined" onClick={handleClickSave}>Speichern</Button>
          <Button variant="contained" color="success" onClick={handleClickSaveAll}>Aktivieren und Speichern</Button>
        </DialogActions>
      </Dialog>

      <Navbar refContact={refContact} refCorps={refCorps}  refVorteile={refVorteile} refVeranstaltungen={refVeranstaltungen} refHaus={refHaus} refVerbindungen={refVerbindungen}/>


      <Router>
        <Routes>
          <Route path="/imprint" element={<Imprint/>} />
          <Route path="/" element={<MainPage refVorteile={refVorteile} refContact={refContact} refCorps={refCorps} refHaus={refHaus} refVerbindungen={refVerbindungen} refVeranstaltungen={refVeranstaltungen}/>} />
        </Routes>
      </Router>
      <Footer />


      {/*WhatsAppIcon bottom right*/}
      
      {/*View on Desktop (Bigger than 900px*/}
      <Box sx={{ display: { xs: 'none', md: 'block' }}}>

      <Box  sx={{position: 'fixed', bottom:'50px', right:'50px'}}>
      <a aria-label="Chat on WhatsApp" href="https://wa.me/+491578485084?text=Bierjunge"> <img alt="Chat on WhatsApp" src={ChatOnWatsappIcon} width={75} height={75}/> </a>
      </Box> 

      </Box>    
      {/*View on Mobile (Smaller than 900px*/}
      <Box sx={{ display: { xs: 'block', md: 'none' }} }>

      <Box  sx={{position: 'fixed', bottom:'20px', right:'20px'}}>
      <a aria-label="Chat on WhatsApp" href="https://wa.me/+491578485084?text=Bierjunge"> <img alt="Chat on WhatsApp" src={ChatOnWatsappIcon} width={50} height={50}/> </a>
      </Box>

      </Box>
    </>
    
  );
}

export default App;
