import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Wappen from "../../Images/wappen.png";


 
interface IProps {
  refContact?: React.MutableRefObject<HTMLInputElement | null>;
  refCorps?: React.MutableRefObject<HTMLInputElement | null>;
  refHaus?: React.MutableRefObject<HTMLInputElement | null>;
  refVerbindungen?: React.MutableRefObject<HTMLInputElement | null>;
  refVeranstaltungen?: React.MutableRefObject<HTMLInputElement | null>;
  refVorteile?: React.MutableRefObject<HTMLInputElement | null>;
}


const ResponsiveAppBar:React.FC<IProps> = (props) => {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  var AppBarExport
  if(window.location.pathname == "/")
  {
    {/* AppBar for MainPage */}
    AppBarExport =  
    <AppBar sx={{ bgcolor: '#387929;' }} position="fixed">
    <Container maxWidth="xl">
      <Toolbar disableGutters>


     {/* Icon in NavBar */}
        <Typography
              component="a"
              href="/"
              sx={{ mr: 2, display: { xs: 'none', md: 'flex' }}}
            >
            <img src={Wappen} alt="GuestphaliaWappen" width={100} height={100} ></img>
        </Typography>

        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleOpenNavMenu}
            color="inherit"
          >
            <MenuIcon />
          </IconButton>

     {/* Icon in Mobile NavBar */}
     <Box sx={{mx:'auto'}}> 
          <Typography
             component="a"
             href="/">
            <img src={Wappen} alt="GuestphaliaWappen" style={{paddingTop:"5px", paddingRight:"50px"}} width={50} height={50} ></img>
          </Typography>
      </Box>

          <Menu
            id="menu-appbar"
            anchorEl={anchorElNav}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
            sx={{
              display: { sm: 'block', md: 'none' },
            }}
          >

        {/* Menupoints in MobileMenu*/}
            <Button
                  key='Corps'
                    sx={{ 
                    my: 2,
                    mr:3,
                    ml:2,
                    color: '#000000',
                    display: 'block' ,
                    fontSize: 21}}

                  onClick={() => (props.refCorps != undefined && props.refCorps.current != undefined) && props.refCorps.current.scrollIntoView() }
                >
                  <Typography>Corps</Typography>     
            </Button>
            <Button
                  key='Vorteile'
                    sx={{ 
                    my: 2,
                    mr:3,
                    ml:2,
                    color: '#000000',
                    display: 'block' ,
                    fontSize: 21}}

                  onClick={() => (props.refVorteile != undefined && props.refVorteile.current != undefined) && props.refVorteile.current.scrollIntoView() }
                >
                  <Typography>Vorteile</Typography>     
            </Button>
            <Button
                  key='Team'
                  sx={{ 
                    my: 2,
                    mr:3,
                    ml:2,
                    color: '#000000',
                    display: 'block' ,
                    fontSize: 21}}

                  onClick={() => (props.refVerbindungen != undefined && props.refVerbindungen.current != undefined) && props.refVerbindungen.current.scrollIntoView() }
                >
                  <Typography>Verbindungen</Typography>   
            </Button>

            <Button
                  key='Reviews'
                  sx={{ 
                    my: 2,
                    mr:3,
                    ml:2,
                    color: '#000000',
                    display: 'block' ,
                    fontSize: 21}}

                    onClick={() => (props.refVeranstaltungen != undefined && props.refVeranstaltungen.current != undefined) && props.refVeranstaltungen.current.scrollIntoView() }
                >
                  <Typography>Veranstaltungen</Typography>   
            </Button>
            <Button
                  key='Reviews'
                  sx={{ 
                    my: 2,
                    mr:3,
                    ml:2,
                    color: '#000000',
                    display: 'block' ,
                    fontSize: 21}}

                    onClick={() => (props.refHaus != undefined && props.refHaus.current != undefined) && props.refHaus.current.scrollIntoView() }
                >
                  <Typography>Haus</Typography>   
            </Button>
            <Button
                  key='Reviews'
                  sx={{ 
                    my: 2,
                    mr:3,
                    ml:2,
                    color: '#000000',
                    display: 'block' ,
                    fontSize: 21}}

                    onClick={() => (props.refContact != undefined && props.refContact.current != undefined) && props.refContact.current.scrollIntoView() }
                >
                  <Typography>Kontakt</Typography>   
            </Button>
          </Menu>
        </Box>

        {/* Menupoints in Navbar*/}
        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            <Button
                  key='Wer'
                  sx={{ 
                    my: 2, 
                    color: 'white ', 
                    display: 'block' , 
                    fontSize: 21, 
                    pr: '5%',
                    pl: '5%'}}

                  onClick={() => (props.refCorps != undefined && props.refCorps.current != undefined) && props.refCorps.current.scrollIntoView() }
                >
                  Corps
            </Button>

            <Button
                  key='Vorteile'
                  sx={{ 
                    my: 2, 
                    color: 'white ', 
                    display: 'block' , 
                    fontSize: 21, 
                    pr: '5%',
                    pl: '5%'}}

                  onClick={() => (props.refVorteile != undefined && props.refVorteile.current != undefined) && props.refVorteile.current.scrollIntoView() }
                >
                  Vorteile
            </Button>


            <Button
                  key='Was'
                  sx={{ 
                    my: 2, 
                    color: 'white ', 
                    display: 'block' , 
                    fontSize: 21, 
                    pr: '5%',
                    pl: '5%'}}

                  onClick={() => (props.refVerbindungen != undefined && props.refVerbindungen.current != undefined) && props.refVerbindungen.current.scrollIntoView() }
                >
                  Verbindungen
            </Button>
            <Button
                  key='Wo'
                  sx={{ 
                    my: 2, 
                    color: 'white ', 
                    display: 'block' , 
                    fontSize: 21, 
                    pr: '5%',
                    pl: '5%'}}

                  onClick={() => (props.refVeranstaltungen != undefined && props.refVeranstaltungen.current != undefined) && props.refVeranstaltungen.current.scrollIntoView() }
                >
                  Veranstaltungen
            </Button>
            <Button
                  key='Wo'
                  sx={{ 
                    my: 2, 
                    color: 'white ', 
                    display: 'block' , 
                    fontSize: 21, 
                    pr: '5%',
                    pl: '5%'}}

                  onClick={() => (props.refHaus != undefined && props.refHaus.current != undefined) && props.refHaus.current.scrollIntoView() }
                >
                  Haus
            </Button>
            <Button
                  key='Contact'
                  sx={{ 
                    my: 2, 
                    color: 'white ', 
                    display: 'block' , 
                    fontSize: 21, 
                    pr: '5%',
                    pl: '5%'}}

                  onClick={() => (props.refContact != undefined && props.refContact.current != undefined) && props.refContact.current.scrollIntoView() }
                >
                  Kontakt
            </Button>          
        </Box>
      </Toolbar>
    </Container>
  </AppBar>

  }


  else
  {
    {/* AppBar for Imprint */}
    AppBarExport = 
    <AppBar sx={{ bgcolor: '#387929;' }} position="fixed">
    <Container maxWidth="xl">
    <Toolbar disableGutters>


     {/* Icon in NavBar */}
     {/*View on Desktop (Bigger than 900px*/}
     <Box sx={{ display: { xs: 'none', md: 'block' }}}>
        <Typography
              component="a"
              href="/"
              sx={{ mr: 2, display: { xs: 'none', md: 'flex' }}}
            >
            <img src={Wappen} alt="GuestphaliaWappen" width={100} height={100} ></img>
        </Typography>
      </Box>

      
      {/* Icon in Mobile NavBar */}
      <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
      <Box sx={{mx:'auto'}}>     
          <Typography
             component="a"
             href="/">
            <img src={Wappen} alt="GuestphaliaWappen" style={{paddingTop:"5px"}} width={50} height={50} ></img>
          </Typography>
      </Box>
      </Box>

      </Toolbar>
    </Container>
  </AppBar>
  }

  return (
    AppBarExport
  );
}
export default ResponsiveAppBar;