import { Box, Typography } from "@mui/material";
import React from "react";
import ParallexComponent from "../Layout/ParallexComponent";
import pic1 from "../../Images/Diashow/1.jpg"
import pic2 from "../../Images/Diashow/2.jpg"
import pic3 from "../../Images/Diashow/3.jpg"
import pic4 from "../../Images/Diashow/4.jpg"
import pic5 from "../../Images/Diashow/5.jpg"
import pic6 from "../../Images/Diashow/6.jpg"
import SimpleImageSlider from "react-simple-image-slider";


interface IProps {
  refCorps?: React.MutableRefObject<HTMLInputElement | null>;
}

const images = [
  { url: pic1 },
  { url: pic2 },
  { url: pic3 },
  { url: pic4 },
  { url: pic5 },
  { url: pic6 },
];

const Wer: React.FC<IProps> = (props) => {
  return (
    <ParallexComponent isFirst={true}>
      <>
        <Typography ref={props.refCorps} variant="h4" align="center" sx={{ pb: "5%" }}><b>Corps Guestphalia Berlin</b></Typography>

        {/*View on Desktop (Bigger than 900px*/}
        <Box sx={{ display: { xs: 'none', md: 'block' } }}>
          <Typography align="center">Das Corps Guestphalia Berlin (gesprochen „Westfalia“) ist eine traditionsreiche Studentenverbindung, die im Jahre 1845 an der Berliner Friedrich-Wilhelms-Universität, der heutigen Humboldt-Universität, gegründet wurde. Die Verbindung vertritt die Prinzipien der akademischen Freiheit, der Lebensfreundschaft, Toleranz gegenüber Andersdenkenden und der Pflege von Traditionen.
            <br></br>
            Unser Corps betont dabei die Bedeutung der Vielfalt innerhalb unserer Gemeinschaft und begrüßt Studenten aus verschiedenen kulturellen und sozialen Hintergründen.
            <br></br>
            Unser Corps Guestphalia ist Teil des Dachverbandes „Kösener Senioren-Convents-Verband“ (KSCV), einer der ältesten und größten Korporationsverbände in Deutschland. Im Rahmen dieses Verbandes engagiert sich unsere Verbindung auch in politischen und gesellschaftlichen Diskussionen, beispielsweise in Fragen der Hochschul- und Bildungspolitik.
            <br></br>
            Unser Corps legt zudem großen Wert auf die Förderung von Bildung und Wissenschaft. So vergibt sie jährlich Stipendien an Studenten und Doktoranden, um ihre akademische Arbeit zu unterstützen. Auch der Kontakt zu anderen Verbindungen im In- und Ausland ist unserem Corps wichtig, um den Austausch und die Vernetzung von Wissen und Erfahrungen zu fördern.
            <br></br>
            Insgesamt steht unser Corps Guestphalia Berlin für eine traditionsreiche und vielseitige Studentenverbindung, die einen Ausgleich zwischen akademischen Fördern und Fordern sowie Spaß an der studentischen Gemeinschaft pflegt.
            <br></br>
            <br></br>
          </Typography>

          <Box minHeight="600px">
            <SimpleImageSlider
              width={"60%"}
              height={"60%"}
              images={images}
              showBullets={true}
              showNavs={true}
              style={{ alignContent: "center", marginLeft: '12%', marginRight: '10%' }} />
          </Box>

        </Box>

        {/*View on Mobile (Smaller than 900px*/}
        <Box sx={{ display: { xs: 'block', md: 'none' } }}>
          <Typography align="center">Das Corps Guestphalia Berlin ist eine traditionsreiche Studentenverbindung, die im Jahre 1845 an der Friedrich-Wilhelms-Universität, der heutigen Humboldt-Universität zu Berlin, gegründet wurde. Die Verbindung vertritt die Prinzipien der akademischen Freiheit, der Lebensfreundschaft und der Pflege von Traditionen.
            <br></br>
            Unser Corps betont dabei die Bedeutung der Vielfalt innerhalb unserer Gemeinschaft und begrüßt Studenten aus verschiedenen kulturellen und sozialen Hintergründen.
            <br></br>
            Unser Corps Guestphalia ist Teil des Dachverbandes „Kösener Senioren-Convents-Verband“ (KSCV), einer der ältesten und größten Korporationsverbände in Deutschland. Im Rahmen dieses Verbandes engagiert sich unsere Verbindung auch in politischen und gesellschaftlichen Diskussionen, beispielsweise in Fragen der Hochschul- und Bildungspolitik.
            <br></br>
            Unser Corps legt zudem großen Wert auf die Förderung von Bildung und Wissenschaft. So vergibt sie jährlich Stipendien an Studenten und Doktoranden, um ihre akademische Arbeit zu unterstützen. Auch der Kontakt zu anderen Verbindungen im In- und Ausland ist unserem Corps wichtig, um den Austausch und die Vernetzung von Wissen und Erfahrungen zu fördern.
            <br></br>
            Insgesamt steht unser Corps Guestphalia Berlin für eine traditionsreiche und vielseitige Studentenverbindung, die einen Ausgleich zwischen akademischen Fördern und Fordern sowie Spaß an der studentischen Gemeinschaft pflegt.
            <br></br>
            <br></br>
          </Typography>

          <Box
            minHeight="200px" pt="5%">

            <SimpleImageSlider
              width={"80%"}
              height={"25%"}
              images={images}
              showBullets={true}
              showNavs={true}
              style={{ alignContent: "center", marginRight: '10%' }} />
          </Box>
        </Box>
      </>
    </ParallexComponent>
  )
}
export default Wer;

