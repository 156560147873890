import React from "react";
import Impressum from "../Content/Impressum";



const Imprint:React.FC = () => {
    return(
        <>
            <Impressum />
        </>
    )
}
export default Imprint;