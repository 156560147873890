import { Box ,Fade,Typography } from "@mui/material";
import React from "react";
import ParallexComponent from "../Layout/ParallexComponent";
import Sempro from "../../Images/Sempro.png"
import VizSensor from 'react-visibility-sensor';
import { useState } from "react";

interface IProps {
    refVeranstaltungen?: React.MutableRefObject<HTMLInputElement | null>;
}

const Veranstaltungen:React.FC<IProps> = (props) => {

    let [active, setActive] = useState(false);

    return(
        <ParallexComponent>
            <>

            <Typography id='Veranstaltungen' ref={props.refVeranstaltungen} variant="h4" align="center" sx={{ pb: "5%"}}><b>Veranstaltungen</b></Typography>
            {/*View on Desktop (Bigger than 900px*/}

            <VizSensor
            partialVisibility={true}
            minTopValue={50}
            onChange={(isVisible: any) => {
            setActive(isVisible);}}>

                <Fade in={active} timeout={500}>

                    <Box sx={{ display: { xs: 'none', md: 'block' }}}>
                    <Typography align="center">
            <Box><img src={Sempro} width={"75%"} height={"75%"} alt="Semesterprogramm23"></img></Box>
            </Typography>
            </Box>
            </Fade>
            </VizSensor>

            {/*View on Mobile (Smaller than 900px*/}
            <Box sx={{ display: { xs: 'block', md: 'none' }} }>
            <Typography align="center">
            <Box><img src={Sempro} width={"100%"} height={"100%"}alt="Semesterprogramm23"></img></Box>       
            </Typography>
            </Box>

            </>
        </ParallexComponent>
    )
}
export default Veranstaltungen;